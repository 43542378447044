import React from 'react';
import s from './ThankYouPageApp.scss';
import {useControllerProps} from '../ControllerContext';
import {withSentryErrorBoundary} from '@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary';
import {THANK_YOU_PAGE_DSN} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf';
import {PageContent as OldPageContent} from './PageContent.deprecated';
import {PageContent} from './PageContent';
import classNames from 'classnames';

export enum ThankYouPageAppDataHook {
  root = 'ThankYouPageAppDataHook.root',
  rootContent = 'ThankYouPageAppDataHook.rootContent',
  blank = 'ThankYouPageAppDataHook.blank',
}

function ThankYouPageApp() {
  const {ssrError, thankYouPageStore} = useControllerProps();
  if (ssrError) {
    return <div data-hook={ThankYouPageAppDataHook.blank} />;
  }

  const MainContent = () => {
    if (thankYouPageStore.shouldEnableNewTyp) {
      return thankYouPageStore.shouldShowFullBackgroundInTYP ? (
        <div data-hook={ThankYouPageAppDataHook.rootContent} className={s.limitContent}>
          <PageContent />
        </div>
      ) : (
        <PageContent />
      );
    }
    return <OldPageContent />;
  };

  return (
    <main
      data-hook={ThankYouPageAppDataHook.root}
      className={classNames(
        thankYouPageStore.shouldEnableNewTyp ? s.root : s.rootOld,
        thankYouPageStore.shouldShowFullBackgroundInTYP ? null : s.limitContent
      )}>
      <MainContent />
    </main>
  );
}

// eslint-disable-next-line import/no-default-export
export default withSentryErrorBoundary(ThankYouPageApp, {
  dsn: THANK_YOU_PAGE_DSN,
  config: {environment: 'Native Component'},
});
